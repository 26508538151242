/*Slider*/
.navbar .navbar-brand-wrapper,.sidebar{
    width: 200px;
}
.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper {
    margin-left: 5px;
    margin-right: 5px;
}
.navbar .navbar-brand-wrapper{
    border-right: none;
}
/*fin slider*/

/*Marco central*/
.navbar .navbar-menu-wrapper{
    width: 100%;
}
.content-wrapper{
    padding:0;
}
.main-panel{
    width:100%;
}
.page-body-wrapper{
    padding-top: 0px;
}
/*fin marco central*/

/* Dialogo */
.p-dialog .p-dialog-header .p-dialog-title{
    font-size: 14px;
}
.p-dialog .p-dialog-header{
    padding: 0.4rem 0.8rem;
}
.p-dialog .p-dialog-content{
    padding: 0 0.5rem 1rem 0.5rem;
}
/* fin Dialogo */

/* Tabla */
.p-dialog .table th,.p-dialog .table td{
    padding: 0.4rem 0.9375rem;
}
/* fin Tabla */

.form-label{
    font-weight: 450;
    margin-bottom: 1px;
}

.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand.brand-logo-mini {
    display: none;
}

.p-fieldset .p-fieldset-legend{
    padding: 10px;
}

/* DataTableDemo.css */

.datatable-crud-demo .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.datatable-crud-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.datatable-crud-demo .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}

.datatable-crud-demo .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-button{
    font-size: 12px;
}

.ui-sidebar-lg{
    width: 700px;
}


/* TabViewDemo.css */

.tabview-demo .tabview-custom i, .tabview-demo .tabview-custom span {
    vertical-align: middle;
}

.tabview-demo .tabview-custom span {
    margin: 0 .5rem;
}

.tabview-demo .p-button {
    margin-right: .25rem;
}

.tabview-demo .p-tabview p {
    line-height: 1.5;
    margin: 0;
}

.toolbaredicion{
    padding: 5px;
    position: sticky;
    position: -webkit-sticky; 
    top: 0; 
}

.p-sidebar .p-sidebar-header{
    padding: 10px;
}

.p-component{
    font-size: 12px;
    line-height: 14px;
}

.p-orderlist .p-orderlist-list .p-orderlist-item{
    padding: 5px;
}

.p-orderlist .p-orderlist-list{
    padding: 0px;
}

/*
* DataTabe
*/
.p-datatable .p-datatable-thead > tr > th{
    padding: 10px;
}
.p-datatable .p-datatable-tbody > tr > td{
    padding: 0px 10px;
}

.tiporecoleccion label{
    font-weight: bold;
    margin-left: 5px;
    font-size: 20px;
}
.tiporecoleccion{
    padding-bottom: 15px;
}
.tiporecoleccion .p-radiobutton{
    margin-bottom: 5px;
}

@media (min-width: 1200px){
    legend {
        font-size: 15px;
    }
}

@media (max-width: 991px){
    .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand.brand-logo-mini {
        display: inline-block;
        width: 1.875rem;
    }
    .navbar .navbar-menu-wrapper {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (max-width: 480px){
    .navbar .navbar-brand-wrapper {
        width: 55px;
    }
    .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper {
        margin-left: 5px;
        margin-right: 1.375rem;
    }
}

.navbar .navbar-brand-wrapper, .navbar .navbar-menu-wrapper{
    border-bottom: none;
}

.content-wrapper{
    background: none;
}

.dropdown .dropdown-toggle:after{
    font: normal normal normal 24px/1 "primeicons";
    content: "\e930";
    font-size: 13px;
}

.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand {
    color: #ffffff;
    font-size: 14px;
    margin-right: 0;
    padding: 5px 0;
}

.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .brand-logo-mini {
    color: black;
}

.cardprincipal{
    padding: 0;
}

.cardprincipal .p-card-body{
    padding-bottom: 0.1rem;
}

.cardTablaVenta .p-card-body{
    padding: 0 1rem;
}

.cardTablaVenta .p-card-content{
    padding: 0.5rem 0;
}

.tablaventa{
    min-height: 250px;
}

.columnatablaventa{
    padding-left: 0;
    padding-right: 0;
}

.columnatotalventa{
    padding-right: 0;
    padding-left: 5px;
}

.p-mr-2 {
    margin-right: 0.5rem!important;
}